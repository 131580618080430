define("discourse/plugins/discourse-pm-auto-responder-for-admins/discourse/connectors/sidebar-footer-actions/pm-auto-responder-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(_args, component) {
      component.showInSidebar = this?.currentUser && this.currentUser.admin;
    }
  };
});