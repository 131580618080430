define("discourse/plugins/discourse-pm-auto-responder-for-admins/discourse/components/auto-pm-toggler", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{action "toggleAutoPm"}}
    @icon={{this.toggleButtonIcon}}
    @class="auto-pm-toggler btn-flat"
    @title={{this.getTitle}}
  />
  */
  {
    "id": "CFJYb9kh",
    "block": "[[[8,[39,0],null,[[\"@action\",\"@icon\",\"@class\",\"@title\"],[[28,[37,1],[[30,0],\"toggleAutoPm\"],null],[30,0,[\"toggleButtonIcon\"]],\"auto-pm-toggler btn-flat\",[30,0,[\"getTitle\"]]]],null]],[],false,[\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-pm-auto-responder-for-admins/discourse/components/auto-pm-toggler.hbs",
    "isStrictMode": false
  });
  class AutoPmToggler extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    constructor() {
      super(...arguments);
    }
    get toggleButtonIcon() {
      return "power-off";
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleButtonIcon", [(0, _object.computed)("storedOverride")]))();
    get getTitle() {
      const propertyPath = "custom_fields.mmn_auto_respond_pm";
      return this.currentUser.get(propertyPath) ? "mmn_auto_respond_pm.disable" : "mmn_auto_respond_pm.enable";
    }
    setClassAndLabel(enabled) {
      const className = "pm-auto-responder-on";
      const method = enabled ? "add" : "remove";
      $("html")[`${method}Class`](className);
    }
    toggleAutoPm() {
      const propertyPath = "custom_fields.mmn_auto_respond_pm";
      const path = this.currentUser.get(propertyPath) ? "disable" : "enable";
      this.currentUser.toggleProperty(propertyPath);
      var loading = true;
      var _this = this;
      (0, _ajax.ajax)(`/mmn_auto_respond_pm/${path}`).catch(e => console.error(e)).then(result => {
        if (result.status != "ok") {
          _this.currentUser.toggleProperty(propertyPath);
        }
      }).finally(() => {
        _this.setClassAndLabel(_this.currentUser.get(propertyPath));
        loading = false;
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleAutoPm", [_object.action]))();
  }
  _exports.default = AutoPmToggler;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AutoPmToggler);
});