define("discourse/plugins/discourse-pm-auto-responder-for-admins/discourse/initializers/extend-for-pm-auto-responder", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const propertyPath = "custom_fields.mmn_auto_respond_pm";
  function setClassAndLabel(enabled) {
    const className = "pm-auto-responder-on";
    const method = enabled ? "add" : "remove";
    $("html")[`${method}Class`](className);
  }
  function initializeWithApi(api, siteSetting) {
    if (!siteSetting.enable_pm_auto_responder_for_admins) return;
    const currentUser = api.getCurrentUser();
    if (!currentUser || !currentUser.get('admin')) return;
    setClassAndLabel(currentUser.get(propertyPath));
  }
  var _default = _exports.default = {
    name: 'extend-for-pm-auto-responder',
    initialize(c) {
      const siteSetting = c.lookup('site-settings:main');
      (0, _pluginApi.withPluginApi)('0.1', api => {
        initializeWithApi(api, siteSetting);
      });
    }
  };
});